import React from "react";
import PropTypes from "prop-types";

/**
 * Generic component for embedded Formstack forms in React
 * Can specify fields to prepopulate with the prepopulate prop
 * @param {*} props 
 */
const FormstackEmbed = (props) => {
    //Use the load count of the iFrame to determine whether its completed or not (yes, this is hacky, but we can't see the contents of the iFrame if its not on the same domain, so we need to make do with this)
    const [iFrameLoadCount, setIFrameLoadCount] = React.useState(0);
    const isFormCompleted = iFrameLoadCount > 1;

    //Formstack form URL
    let formURL = props.url;

    //Add to the URL for autopopulated fields if they've been specified - strip out any null values in this too, Formstack doesn't do well with them
    if (props.prepopulate) {
        let prepopulateFields = Object.keys(props.prepopulate);
        //Filter out nulls
        prepopulateFields = prepopulateFields.filter(field => props.prepopulate[field])
        
        prepopulateFields = prepopulateFields.map(field => {
            return `${field}=${props.prepopulate[field]}`;
        }) 
        let queryString = prepopulateFields.join('&');
        if (queryString) {
            formURL += `?${queryString}`;
        }
    }

    //Fire the onSubmit event to the parent component when we've determined this form as submitted
    if (props.onSubmit && isFormCompleted) {
        props.onSubmit(true);
        setIFrameLoadCount(0);
    }

    return (
        <div style={{height:'100%', width:'100%'}}>
            <iframe 
                src={formURL}
                title="IFrame" 
                frameBorder="0"
                style={{
                    width:'100%', height:'100%'
                }}
                onLoad={() => setIFrameLoadCount(iFrameLoadCount + 1)}
            ></iframe>
        </div>
    )
}

FormstackEmbed.propTypes = {
    url:PropTypes.string.isRequired,
    prepopulate:PropTypes.objectOf(PropTypes.string),
    onSubmit:PropTypes.func,
}

export default FormstackEmbed;