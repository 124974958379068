import React from "react";
import PropTypes from 'prop-types';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormstackEmbed from './formstackEmbed';

const FormstackEmbedPopup = (props) => {
    function handleClose() {
        props.onSubmit();
    }

    return (
        <Dialog fullWidth open={props.open} onBackdropClick={() => handleClose()}>
            <DialogTitle id="alert-dialog-title">Give Feedback</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText id="alert-dialog-description" style={{height:'500px'}}>
                    <FormstackEmbed url={props.url} prepopulate={props.prepopulate} onSubmit={props.onSubmit}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

FormstackEmbedPopup.propTypes = {
    open:PropTypes.bool.isRequired,
    url:PropTypes.string.isRequired,
    prepopulate:PropTypes.objectOf(PropTypes.string),
    onSubmit:PropTypes.func,
}

export default FormstackEmbedPopup;