/**
 * Layout component used across all pages in this app
 */

import React, { useContext } from "react"

/*Gatsby*/
import { Link } from "gatsby";

/*Material UI*/
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';


// @ts-ignore
import FullLogo from "../assets/HOS Logo.png";
// @ts-ignore
import CondensedLogo from "../assets/HOS_Logo_Condensed.png";
// @ts-ignore
import BackgroundImage from "../assets/signupBackground.jpg";

import { globalContext } from '../GlobalContext';

//Global CSS
import "./layout.css";
import FormstackEmbedPopup from "./formstack/formstackEmbedPopup";

const FORMSTACK_FEEDBACK_FORM_URL = "https://hopeonesource.formstack.com/forms/h1s_me_app_feedback_survey";

/**
 * Global layout container used (currently) across all pages in this app. Includes nav bar, nav buttons, global loader, global error page
 * @param {*} props 
 * @author BrianCastor
 */
export default function Layout({ children }) {
  const context = useContext(globalContext);
  const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
  const [rateExperiencePopupOpen, setRateExperiencePopupOpen] = React.useState(false);

  //const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const userID = context.currentUser ? context.currentUser.Id : '';

  // @ts-ignore
  const MobileNavBar = (props) => {
    return (
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Link to="/home">
              <img src={CondensedLogo} style={{ height: '45px', width:'45px', marginLeft:'5px', marginTop:'2px'}} alt="HopeOneSource" />
            </Link>
          </Grid>
          <Grid item style={{textAlign:'center'}}>
            <Button color="primary" variant="outlined" onClick={() => setRateExperiencePopupOpen(true)}>Give Feedback</Button>
          </Grid>
          <Grid item style={{textAlign:'right'}}>
            {context.isRegistered() ?
              (<IconButton edge="end" color="inherit" aria-label="menu" style={{marginRight:'5px'}} onClick={() => setSideMenuOpen(true)}>
                <MenuIcon />
              </IconButton>)
              :
              (<div style={{width:'45px', height:'45px', marginRight:'5px', marginTop:'2px'}}/>)
            }
            </Grid>
        </Grid>
    )
  }

  // @ts-ignore
  const DesktopNavBar = (props) => {
    return (
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <Link to="/home">
            <img src={FullLogo} style={{ maxHeight: '45px' }} alt="HopeOneSource" />
          </Link>
        </div>
        <Button color="primary" variant="outlined" onClick={() => setRateExperiencePopupOpen(true)}>Give Feedback</Button>
        {context.isRegistered() ?
          (
            <div>
              <Button color="inherit" component={Link} to="/favorites" style={{ marginLeft: '20px' }}>Favorites</Button>
              <Button color="inherit" component={Link} to="/profile" style={{ marginLeft: '10px' }} >Profile</Button>
            </div>
          )
          :
          (
            <div>
              <Button color="primary" variant="contained" style={{ marginLeft: '10px' }} component={Link} to="/signup">Sign Up</Button>
            </div>
          )}
      </Toolbar>
    )
  }

  return (
    <>
      <React.Fragment>
        {/*Top Navigation Bar*/}
        <AppBar position="sticky" color="default">
          {/*isMobile ? (<MobileNavBar />) : (<DesktopNavBar />)*/}
          {/*Need to dictate display of these with media queries in CSS because Gatsby's server side rendering does not play well with react-responsive*/}
          <div className="mobileNavBar">
            <MobileNavBar/>
          </div>
          <div className="desktopNavBar">
            <DesktopNavBar className="desktopNavBar"/>
          </div>
        </AppBar>
        {/*Background Image*/}
        <div style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),  rgba(0, 0, 0, 0.5)), url(${BackgroundImage})`, maskImage:'linear-gradient(to bottom, black 50%, transparent 100%)', WebkitMaskImage:'linear-gradient(to bottom, black 50%, transparent 100%)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', position: 'absolute', top: 0, left: 0, right: 0, height: '600px', zIndex: -1 }} />
        {/*Content Rendered though Sub-Components*/}
        <main>{children}</main>
        {/*Side Menu*/}
        <Drawer anchor='right' open={sideMenuOpen} onClose={() => setSideMenuOpen(false)}>
          <List style={{ width: '200px' }}>
            <ListItem button component={Link} to="/home" onClick={() => setSideMenuOpen(false)}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/favorites" onClick={() => setSideMenuOpen(false)}>
              <ListItemIcon><FavoriteIcon /></ListItemIcon>
              <ListItemText primary="Favorites" />
            </ListItem>
            <ListItem button component={Link} to="/profile" onClick={() => setSideMenuOpen(false)}>
              <ListItemIcon><PersonIcon /></ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
          </List>
        </Drawer>
        <FormstackEmbedPopup url={FORMSTACK_FEEDBACK_FORM_URL} prepopulate={{'UserID':userID}} open={rateExperiencePopupOpen} onSubmit={() => setRateExperiencePopupOpen(false)}/>
      </React.Fragment>
    </>
  )
}